import * as React from "react";
import Section from "./section";
import { useStaticQuery, graphql, navigate } from "gatsby";

import Button from "../controls/button";
import "./hero.css";

const Hero: React.FC = () => {
  const { background } = useStaticQuery(graphql`
    {
      background: allImageSharp(
        filter: { fixed: { originalName: { eq: "bannertest4.png" } } }
      ) {
        nodes {
          original {
            width
            src
            height
          }
        }
      }
      years: allInventoryJson {
        distinct(field: year)
      }
      sections: allInventoryJson {
        distinct(field: section)
      }
      brands: allInventoryJson {
        distinct(field: brand)
      }
    }
  `);

  const [] = React.useState({
    brand: "",
    section: "Carros",
    year: ["", ""]
  });

  return (
    <Section className="flex h-hero">
      <div
        className="h-full w-full"
        style={{
          backgroundImage: `url(${background.nodes[0].original.src})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover"
        }}
      ></div>
    </Section>
  );
};

export default Hero;
